import { DiscussionEmbed } from "disqus-react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import * as React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import "../styles/banner.css"
import "../styles/blog.css"
const Blog = ({ data, location }) => {
  return (
    <>
      {data.allWpPost.nodes.map(
        ({ title, slug, seoDescription, seo, body }) => (
          <Helmet>
            <link rel="canonical" href={seoDescription.canonicalLink} />
            <title>{seoDescription.seoTitle}</title>
            <meta name="description" content={seo.metaDesc || body} />
            <body id="blog-inner-page" className={slug} />
          </Helmet>
        )
      )}
      <>
        <header>
          {data.allWpPost.nodes.map(({ customSchema }) => (
            <div
              dangerouslySetInnerHTML={{ __html: customSchema.customSchema }}
            ></div>
          ))}
        </header>
        <Layout>
          {data.allWpPost.nodes.map(({ blogSection }) => (
            <div className="blogPage">
             
              <section className="blogbanner">
                <img
                  src={blogSection.topBannerImage.node.localFile.publicURL}
                  alt={blogSection.topBannerImage.node.altText}
                />
              </section>
            </div>
          ))}
          {/* <section id="Breadcrumb">
       <div className="container">
        <div className="container-width">
         {data.allWpPost.nodes.map(({title}) => (
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/blog">Blog</Link></li>
            <li>{title}</li>
          </ul>
          ))}
        </div>
       </div> 
     </section> */}
          <section className="inner-content">
            <div className="container">
              <div className="container-width">
                {data.allWpPost.nodes.map(({ title, blogSection }) => (
                  <>
                    <h1
                      className="btitle"
                      dangerouslySetInnerHTML={{ __html: title }}
                    ></h1>
                    <div
                      dangerouslySetInnerHTML={{ __html: blogSection.body }}
                    ></div>
                  </>
                ))}
              </div>
            </div>
          </section>
          <section className="blog-author-bio">
            <div className="container">
              {/* <div className="container-width">
                {data.allWpPost.nodes.map(
                  ({ title, blogSection, author, date }) => (
                    <div className="wrap">
                      <div className="row">
                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                          <div className="author-img">
                            <img
                              src={author.node.avatar.url}
                              alt={author.node.name}
                            />
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-10 col-md-10 col-lg-10">
                          <div className="author-name">
                            <p>{date}</p>
                            <h4>
                              <span>By</span> {author.node.name}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>{" "} */}
              {data.allWpPost.nodes.map(
                ({ title, blogSection, author, date }) => (
                  <div class="authorHolder">
                    <div class="authorImage">
                      <img
                        src={author.node.avatar.url}
                        alt={author.node.name}
                        loading="lazy"
                        width="96"
                        height="96"
                        decoding="async"
                      />
                    </div>
                    <div class="authorDetails">
                      <p>{date}</p>
                      <h4>
                        <span>By </span> {author.node.name}
                      </h4>
                    </div>
                  </div>
                )
              )}
            </div>
          </section>
          <div className="disqus-comments">
            <div className="container">
              <div className="container-width">
                <DiscussionEmbed shortname="positionsquaredblog" />
              </div>
            </div>
          </div>
        </Layout>
      </>
    </>
  )
}

Blog.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  query ($BlogId: String!) {
    allWpPost(filter: { id: { eq: $BlogId } }) {
      nodes {
        id
        slug
        title
        author {
          node {
            avatar {
              url
            }
            name
            description
          }
        }
        blogSection {
          blogFeaturedImage {
            node {
              altText
              localFile {
                publicURL
              }
            }
          }
          topBannerImage {
            node {
              altText
              localFile {
                publicURL
              }
            }
          }
          body
        }
        date(formatString: "LL")
        customSchema {
          customSchema
        }
        seo {
          opengraphTitle
          opengraphDescription
          metaDesc
          title
          twitterTitle
          twitterImage {
            localFile {
              publicURL
            }
          }
          twitterDescription
          opengraphImage {
            altText
            localFile {
              publicURL
            }
          }
        }
        seoDescription {
          seoTitle
          seoDescription
          canonicalLink
        }
      }
    }
  }
`

export default Blog
